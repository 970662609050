<template>
    <div class="option-step" v-if="sizeOptions && flapOptions && positionOptions">
        <div class="option-step__left">
            <div class="option-step__options" style="display: flex;">
                <div>
                    <div class="option-step__title">Size
                      <HelpTopic class="select__help-topic" :iframeData="helpTopicUrlSize" v-if="helpTopicUrlSize" />
                    </div>
                    <RadioGroupItems
                        class="option-step__item"
                        :vertical="true"
                        :items="currentSettings.groups.size.choices.map(i => { return {label: i.name, key: i.name, value: i}})"
                        :key="'id'"
                        v-model="sizeValue"
                        @update:modelValue="updValues(sizeValue, 'size')"/>
                </div>
                <div class="option-step__" v-if="sizeValue && sizeValue.colour_group">
                    <span class="select-color__title">Select colour:</span>
                    <SelectColor :colour="sizeValue.colour" :colourGroup="sizeValue.colour_group" @submit="selectColor"/>
                </div>
            </div>
            <div class="option-step__options">
                <div class="option-step__title">Flaps
                  <HelpTopic class="select__help-topic" :iframeData="helpTopicUrlFlap" v-if="helpTopicUrlFlap" />
                </div>
                <RadioGroupItems
                    class="option-step__item"
                    :items="currentSettings.groups.flap.choices.map(i => { return {label: i.name, key: i.name, value: i}})"
                    :vertical="true"
                    :key="'id'"
                    v-model="flapValue"
                    @update:modelValue="updValues(flapValue, 'flap')"/>
            </div>
        </div>
        <div class="option-step__right">
            <div class="option-step__options" style="margin-bottom: 0;">

                <span style="color: var(--LayoutIconPrimary);">
                    <svg class="pet-door__image" fill="currentColor" v-if="positionValue?.name.toLowerCase()?.trim() == 'lower left'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M0 0v512h320V0H0zM289.4 483.2H30.6V28.8h258.9v454.4zm-175-23.5l-7-24.5v-41.7s0 0 0 0v-3.1H30.6v96h76.9v-10.7c2.1-.9 3.9-2.4 5.3-4.5c2.2-3.4 2.8-7.6 1.7-11.6zm-10 23.4h-71v-89.4h71v31l-6.6-23.2c-.2-.7-.8-1.2-1.4-1.2H40.3c-.5 0-.9 .3-1.2 .7c-.3 .4-.4 1-.2 1.5l1.3 4.5v62.8c0 .9 .7 1.7 1.5 1.7h17.6c2.1 3.1 5.5 5 9.1 5h35c.4 0 .7 0 1.1-.1v6.6zM43.1 417.5l14.2 50c.1 .3 .2 .5 .2 .7H43.1v-50.8zm67.2 51.8c-1.6 2.5-4.2 3.9-6.9 3.9h-35c-3.7 0-7-2.7-8.2-6.7l-17.9-62.8h53.1l9.1 32v.3h.1l7 24.7c.8 2.9 .4 6.1-1.2 8.6zm-43-29.4c-.9-.7-1.8-1.6-2.6-2.7s-1.3-2.3-1.7-3.4-.4-2.1-.1-2.9c.3-1 .9-1.5 1.8-1.5s1.9 .3 2.9 1.1c.9 .7 1.7 1.6 2.5 2.7s1.3 2.2 1.6 3.3 .4 2 .1 2.8c-.3 .9-.9 1.4-1.8 1.5s-1.8-.2-2.9-.9zM90 448.5c-.4 .4-1 .7-1.9 .9c-.4 .1-1 .1-1.5 0c-.4-.1-.9-.2-1.6-.4s-1.2-.3-1.5-.4c-.5-.1-1.1-.1-1.5-.1s-.9 .2-1.4 .5c-.3 .2-.8 .4-1.4 .9s-1.1 .7-1.4 .9c-.6 .3-1.2 .5-1.7 .6c-2 .4-3.4-.5-4-2.7c-.5-1.7-.4-3.6 .1-5.7s1.3-3.9 2.5-5.3c1.2-1.6 2.3-2.4 3.6-2.5s2.8 .5 4.6 1.7c1.7 1.1 3.3 2.5 4.7 4.3s2.3 3.3 2.8 4.8c.3 1.1 .2 2-.3 2.5zM70.3 430c-.9-.7-1.7-1.6-2.4-2.7s-1.2-2.2-1.5-3.3c-.4-1.3-.4-2.5-.2-3.5c.3-1.1 1-1.7 2-1.7c.9 0 1.8 .4 2.7 1.2c.8 .7 1.6 1.6 2.2 2.7s1.2 2.1 1.4 3.1c.4 1.3 .4 2.4 .2 3.4c-.3 1.2-.9 1.8-1.9 1.8c-.8 .1-1.7-.3-2.7-1.1zm5.7-7.3c0-1.1 .3-2 .6-2.7c.4-.8 1.1-1.2 1.9-1.2c.9 0 1.8 .5 2.6 1.5c.7 .9 1.2 2 1.6 3.2c.3 1 .4 2 .4 3.1s-.2 2-.6 2.7c-.4 .8-1 1.2-1.8 1.3c-.9 .1-1.9-.4-2.7-1.5c-.7-.9-1.3-2-1.7-3.3c-.3-1-.4-2-.4-3.1zm14.3 12.2c-.1 1.1-.3 2-.6 2.7c-.4 .8-1 1.2-1.8 1.3s-1.5-.3-2.1-1c-.6-.7-1-1.5-1.3-2.6s-.4-2.1-.3-3.2 .3-2 .7-2.7c.4-.8 1.1-1.2 1.8-1.2s1.4 .4 2.1 1.2c.6 .7 1 1.5 1.3 2.5s.4 2 .3 3z"/></svg>
                    <svg class="pet-door__image" fill="currentColor" v-else-if="positionValue?.name.toLowerCase()?.trim() == 'lower middle'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M0 512h320V0H0M130 400.5h53.6l9.2 32v.3h.1l7.1 24.7c.9 2.9 .4 6.1-1.2 8.6c-1.7 2.5-4.2 3.9-7 3.9h-35.4c-3.8 0-7.1-2.7-8.3-6.7l-18-62.8zm15.4 64.5h-14.6v-50.8l14.4 50c.1 .3 .2 .5 .2 .7zm47.4-43.5l-6.7-23.2c-.2-.7-.8-1.2-1.4-1.2H128c-.5 0-.9 .3-1.2 .7s-.4 1-.2 1.5l1.3 4.5v62.8c0 .9 .7 1.7 1.5 1.7h17.8c2.2 3.1 5.5 5 9.2 5h35.4c.4 0 .7 0 1.1-.1v6.6h-71.7v-89.4h71.7v31zm97.9 61.6h-94.9v-10.7c2.1-.9 4-2.4 5.3-4.5c2.2-3.4 2.8-7.6 1.7-11.6l-7-24.5v-41.7s0 0 0 0v-3.1h-77.7v96H29.2V28.8h261.5v454.4zM172.6 432.1c-.3-1-.4-2.1-.3-3.2c.1-1.1 .3-2 .7-2.7c.4-.8 1.1-1.2 1.8-1.2c.7 0 1.4 .4 2.1 1.2c.6 .7 1 1.5 1.3 2.5c.3 1 .4 2 .3 3s-.3 2-.6 2.7c-.4 .8-1.1 1.2-1.8 1.3s-1.5-.3-2.2-1c-.6-.7-1.1-1.5-1.3-2.6zM151 430.5c-.3-1.1-.4-2.1-.1-2.9c.3-1 .9-1.5 1.9-1.5c.9 0 1.9 .3 3 1.1c.9 .7 1.7 1.6 2.5 2.7s1.3 2.2 1.6 3.3s.4 2 .1 2.8c-.3 .9-.9 1.4-1.8 1.5c-.9 .1-1.9-.2-2.9-.9c-.9-.7-1.8-1.6-2.6-2.7c-.8-1.2-1.3-2.3-1.7-3.4zm3.4-9.7c-.4-1.3-.4-2.5-.2-3.5c.3-1.1 1-1.7 2-1.7c.9 0 1.8 .4 2.8 1.2c.8 .7 1.6 1.6 2.2 2.7s1.2 2.1 1.4 3.1c.4 1.3 .4 2.4 .2 3.4c-.3 1.2-.9 1.8-1.9 1.8c-.8 .1-1.8-.3-2.7-1.1c-.9-.7-1.7-1.6-2.4-2.7s-1.2-2.2-1.5-3.3zm10 1.8c-.3-1-.4-2-.4-3.1s.3-2 .6-2.7c.4-.8 1.1-1.2 1.9-1.2c1 0 1.8 .5 2.7 1.5c.7 .9 1.3 2 1.6 3.2c.3 1 .4 2 .4 3.1c0 1.1-.2 2-.6 2.7c-.4 .8-1 1.2-1.8 1.3c-1 .1-1.9-.4-2.8-1.5c-.8-.9-1.3-2-1.7-3.3zm-1.8 11.8c1.2-1.6 2.4-2.4 3.6-2.5s2.8 .5 4.7 1.7c1.7 1.1 3.3 2.5 4.8 4.3c1.4 1.7 2.4 3.3 2.8 4.8c.3 1.1 .2 2-.3 2.5c-.4 .4-1 .7-1.9 .9c-.4 .1-1 .1-1.5 0c-.4-.1-.9-.2-1.6-.4c-.7-.2-1.2-.3-1.6-.4c-.6-.1-1.1-.1-1.5-.1s-.9 .2-1.4 .5c-.3 .2-.8 .4-1.4 .9c-.6 .4-1.1 .7-1.4 .9c-.6 .3-1.2 .5-1.7 .6c-2.1 .4-3.5-.5-4-2.7c-.5-1.7-.4-3.6 .1-5.7c.5-2.1 1.3-3.9 2.5-5.3z"/></svg>
                    <svg class="pet-door__image" fill="currentColor" v-else-if="positionValue?.name.toLowerCase()?.trim() == 'lower right'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M0 512h320V0H0M216.5 400.5h53.6l9.2 32v.3h.1l7.1 24.7c.9 2.9 .4 6.1-1.2 8.6c-1.7 2.5-4.2 3.9-7 3.9h-35.4c-3.8 0-7.1-2.7-8.3-6.7l-18-62.8zM232 465.1h-14.6v-50.8l14.4 50c.1 .3 .2 .5 .2 .7zm47.4-43.5l-6.7-23.2c-.2-.7-.8-1.2-1.4-1.2h-56.8c-.5 0-.9 .3-1.2 .7s-.4 1-.2 1.5l1.3 4.5v62.8c0 .9 .7 1.7 1.5 1.7h17.8c2.2 3.1 5.5 5 9.2 5h35.4c.4 0 .7 0 1.1-.1v6.6h-71.7v-89.4h71.7v31zm11.4 61.6h-8.4v-10.7c2.1-.9 4-2.4 5.3-4.5c2.2-3.4 2.8-7.6 1.7-11.6l-7-24.5v-41.7s0 0 0 0v-3.1h-77.7v96H29.2V28.8h261.5v454.4zm-53.2-52.7c-.3-1.1-.4-2.1-.1-2.9c.3-1 .9-1.5 1.9-1.5c.9 0 1.9 .3 3 1.1c.9 .7 1.7 1.6 2.5 2.7s1.3 2.2 1.6 3.3s.4 2 .1 2.8c-.3 .9-.9 1.4-1.8 1.5c-.9 .1-1.9-.2-2.9-.9c-.9-.7-1.8-1.6-2.6-2.7c-.8-1.2-1.3-2.3-1.7-3.4zm21.5 1.6c-.3-1-.4-2.1-.3-3.2c.1-1.1 .3-2 .7-2.7c.4-.8 1.1-1.2 1.8-1.2c.7 0 1.4 .4 2.1 1.2c.6 .7 1 1.5 1.3 2.5c.3 1 .4 2 .3 3s-.3 2-.6 2.7c-.4 .8-1.1 1.2-1.8 1.3s-1.5-.3-2.2-1c-.6-.7-1.1-1.5-1.3-2.6zm-8.2-9.5c-.3-1-.4-2-.4-3.1s.3-2 .6-2.7c.4-.8 1.1-1.2 1.9-1.2c1 0 1.8 .5 2.7 1.5c.7 .9 1.3 2 1.6 3.2c.3 1 .4 2 .4 3.1c0 1.1-.2 2-.6 2.7c-.4 .8-1 1.2-1.8 1.3c-1 .1-1.9-.4-2.8-1.5c-.8-.9-1.3-2-1.7-3.3zm-10-1.8c-.4-1.3-.4-2.5-.2-3.5c.3-1.1 1-1.7 2-1.7c.9 0 1.8 .4 2.8 1.2c.8 .7 1.6 1.6 2.2 2.7s1.2 2.1 1.4 3.1c.4 1.3 .4 2.4 .2 3.4c-.3 1.2-.9 1.8-1.9 1.8c-.8 .1-1.8-.3-2.7-1.1c-.9-.7-1.7-1.6-2.4-2.7s-1.2-2.2-1.5-3.3zm8.2 13.6c1.2-1.6 2.4-2.4 3.6-2.5s2.8 .5 4.7 1.7c1.7 1.1 3.3 2.5 4.8 4.3c1.4 1.7 2.4 3.3 2.8 4.8c.3 1.1 .2 2-.3 2.5c-.4 .4-1 .7-1.9 .9c-.4 .1-1 .1-1.5 0c-.4-.1-.9-.2-1.6-.4c-.7-.2-1.2-.3-1.6-.4c-.6-.1-1.1-.1-1.5-.1s-.9 .2-1.4 .5c-.3 .2-.8 .4-1.4 .9c-.6 .4-1.1 .7-1.4 .9c-.6 .3-1.2 .5-1.7 .6c-2.1 .4-3.5-.5-4-2.7c-.5-1.7-.4-3.6 .1-5.7c.5-2.1 1.3-3.9 2.5-5.3z"/></svg>
                    <img v-else class="pet-door__image" :src="doorPositionImage" alt="door position"/>      
                </span>
                
                <!-- <img  class="pet-door__image" :src="doorPositionImage" alt="door position"/> -->
                <div class="pet-door__title">Position
                  <HelpTopic class="select__help-topic" :iframeData="helpTopicUrlPosition" v-if="helpTopicUrlPosition" />
                </div>
                <RadioGroupItems
                    class="option-step__item"
                    :items="currentSettings.groups.position.choices.map(i => { return {label: i.name, key: i.name, value: i}})"
                    :vertical="true"
                    :key="'id'"
                    v-model="positionValue"
                    @update:modelValue="updValues(positionValue, 'position')"/>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue'
import RadioGroupItems from '../../../components/form/RadioGroupItems.vue'
import { useOptionLogic } from "../../../composables/estimate/create"
import SelectColor from '../../elements/SelectColor.vue'
import HelpTopic from "../../elements/HelpTopic";

export default({
    props: ['items', 'currentSection', 'data', 'currentSettings', 'selection'],
    components: {
        RadioGroupItems,
        SelectColor,
      HelpTopic
    },
    setup(props, { emit }) {
        const sizeOptions = ref(null)
        const sizeValue = ref({})

        const flapOptions = ref(null)
        const flapValue = ref({})

        const positionOptions = ref(null)
        const positionValue = ref({})
        const helpTopicUrlSize = computed(() => {
         return  props.data?.steps.find(el => el.option_code == 'PET').option_settings?.groups.find(el => el.name == 'size').help_topic_url
        })
        const helpTopicUrlFlap = computed(() => {
          return  props.data?.steps.find(el => el.option_code == 'PET').option_settings?.groups.find(el => el.name == 'flap').help_topic_url
        })
        const helpTopicUrlPosition = computed(() => {
          return  props.data?.steps.find(el => el.option_code == 'PET').option_settings?.groups.find(el => el.name == 'position').help_topic_url
        })
        const petDoorColor = computed(() => {
          return  sizeOptions.value.find(el => el.name == Object.values(sizeValue.value)[0]).colour
        })
        const doorPositionImage = computed(() => {
            return positionValue.value.image

        })

        const optionLogic = useOptionLogic(props)


        getData()
        function getData() {
            optionLogic.getData1(sizeOptions, sizeValue, 'size')
            optionLogic.getData1(flapOptions, flapValue, 'flap')
            optionLogic.getData1(positionOptions, positionValue, 'position')
        }

        function selectColor(color) {
          optionLogic.selectColor(color.id, sizeValue.value.id, 'size')
          sizeValue.value.colour = color.id
          emit('updateOptionColour')
        }


        function updValues(values, groupName) {
            optionLogic.selectItem(values, groupName)
        }

        return {
            helpTopicUrlSize,
            helpTopicUrlFlap,
            helpTopicUrlPosition,
            petDoorColor,
            optionLogic,
            positionOptions,
            flapOptions,
            sizeOptions,
            sizeValue,
            flapValue,
            positionValue,
            doorPositionImage,
            updValues,
            getData,
            // doorSizeValue,
            selectColor,
            // sizeColorValue
        }

    },
})
</script>


<style scoped lang="scss">
.option-step {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.pet-door {
    &__image {
        max-height: 195px;
        display: flex;
        margin-right: auto;
        margin-bottom: 10px;
    }
    &__title {
        font-size: 24px;
        margin-bottom: 10px;
        text-align: left;
    }
}
.select-color {
    &__title {
        margin-bottom: 10px;
        display: block;
    }
}
</style>
